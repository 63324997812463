import React from 'react'
import { SEO, Wrapper, Header } from '../components'
import styled from '@emotion/styled'
import { LocaleContext } from '../components/Layout'
import theme from '../styles/theme'


const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
    text-align: justify;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
    text-align: center;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 4rem 0 1rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;


const TermsAndConditionsOfSale = ({
  location,
  pageContext: {
    locale
  }
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const context = { slug: 'terms-and-conditions-of-sale', parent: null };
  
  return (
    <React.Fragment>
      <SEO title={`${i18n.defaultTitleAlt} - Terms and conditions of sale`} pathname={location.pathname} locale={locale} />
      <Header {...context} />
      <IndexWrapper>
        <Inner>
          <h2>General Terms and conditions of sale</h2>
          <h3>Preamble</h3>
          <p>
            These General Terms and Conditions of Sale (hereinafter the "GTC") apply to the sales part 
            of the site DISCHR.COM.
          </p>
          <p>
            The GTC apply to all sales of Artworks made between DISCHR.COM and the Buyer. 
            They are intended to govern the relationship between DISCHR.COM and Buyers of Artworks 
            on DISCHR.COM website.
          </p>
          
          <h3>1. Conclusion of the sales contract between the Buyer and DISCHR.COM</h3>
          <ul>
            <li>
              <p>
                1.1 The Artworks are presented on the Site with a description enabling the Buyer to know their essential 
                characteristics and their price.
              </p>
            </li>
            <li>
              <p>1.2 The Buyer selects the Artwork(s) he wishes to purchase.</p>
            </li>
            <li>
              <p>
                1.3 He confirms his choice of Artwork(s), reads and accepts the present GTC by a validation click. 
                He proceeds immediately to the payment by credit card via DISCHR.COM's Stripe integration.
              </p>
            </li>
            <li>
              <p>
                1.4 The Buyer receives an email confirming that his order or offer has been taken into account.
              </p>
            </li>
          </ul>

          <h3>2. Price and payment</h3>
          <p>
            The purchase price of the Artwork is set by DISCHR.COM. It is mentioned including all taxes in euros 
            on the description sheet, but excluding delivery costs, the latter being 
            specified during the validation of the shopping cart. If customs duties, local taxes or import duties 
            are payable, these duties are the responsibility of the Buyer and are his entire responsibility, 
            both in terms of declarations and payments to the competent authorities and/or organizations. 
            It is the Buyer's responsibility to obtain information from the competent authorities.
          </p>
          <p>
            The Buyer will be debited with the price of the Artwork purchased plus shipping costs. 
            He will be debited with a delay of 5 days from the moment he validated his order.
          </p>

          <h3>3. Payment security</h3>
          <p>
            DISCHR.COM has chosen high-performance and rigorous tools in terms of payment security. 
            DISCHR.COM has adopted for the means of payment the Stripe System solution, one of the leaders in online payment, 
            using the SSL (Secure Socket Layer) and 3D Secure encryption process.
          </p>
          <p>
            DISCHR.COM has no access to confidential information relating to the means of payment that the Buyer uses 
            during the payment process. This is why the Buyer's bank details will be requested at each new order. 
            Indeed, only Stripe has confidential information that remains inaccessible to third parties.
          </p>
          <p>
            Although DISCHR.COM uses encrypted security software, the security of information and payments transmitted 
            over the Internet or via e-mail cannot be guaranteed. DISCHR.COM shall not be liable for damages resulting 
            from the use of electronic means of communication, including (but not limited to) damages resulting from 
            failure or delay in the transmission of electronic communications, interception or manipulation 
            of electronic communications by third parties or by computer programs used for electronic 
            communications and for the transmission of viruses.
          </p>

          <h3>4. Method and cost of shipping</h3>
          <p>
            The Buyer has the possibility of having the Artwork delivered to his/her home. 
            The Artwork is delivered to the address provided by the Buyer 
            at the time of his order. In this case, DISCHR.COM will pack the Artwork and 
            make it available to a carrier. The shipping costs will be accepted by the Buyer at the time of 
            the validation of his shopping cart.
          </p>
          <p>
            Depending on the Buyer's delivery address, different tax rules and other charges may apply. 
            If the Buyer wishes to be delivered outside the French territory, he may be 
            required to pay import duties upon receipt of his Artwork. DISCHR.COM can't control 
            these charges and can't predict their amount. The Buyer will be responsible 
            for the payment of these taxes and customs duties not included.
          </p>
          <p>
            The Buyer may contact his local customs office for more information before placing his order.
          </p>

          <h3>5. Personal data</h3>
          <p>
            all data collected as part of the Service when placing orders are processed 
            by DISCHR.COM for the purpose of processing such orders.
          </p>
          <p>
            The information and data of Buyers relating to delivery are transmitted to DISCHR.COM 
            for the sole purpose of enabling DISCHR.COM to ship the Works ordered. 
            They can in no case be used for other purposes.
          </p>
          <p>
            DISCHR.COM has access only to the personal data provided 
            by the Buyers when ordering.
          </p>

          <h3>6. Partial nullity</h3>
          <p>
            If one or more stipulations of these GTC are held to be invalid or declared as such 
            in application of a law, regulation or final decision of a competent jurisdiction, 
            the other stipulations will retain their full force and scope.
          </p>

          <h3>7. Applicable law</h3>
          <p>
            The present general conditions are subject to French law. Any dispute relating to their interpretation 
            and/or execution is subject to French jurisdiction.
          </p>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  )
}

export default TermsAndConditionsOfSale
